import helmets from '../../assets/helmets.jpg'
import helmetsMobile from '../../assets/helmets-mobile.png'
import santiagoPasman from '../../assets/santiago-pasman.jpg'
import jorgeCaceres from '../../assets/jorge_caceres.jpg'
import logoPampa from '../../assets/logo-pampa.png'
import { Slider } from '../../components'

interface AssociatesInterface {
  name: string
  position: string
  description: string
  image: string
  email: string
}

const associates: AssociatesInterface[] = [
  {
    name: 'Santiago Pasman',
    position: 'Presidente',
    description:
      '25 años en Grupo Financiero Galicia, desempeñándose desde 2014 al 2019 como Gerente General de Galicia Warrants.',
    image: santiagoPasman,
    email: 'spasman@pampanyg.com.ar',
  },
  {
    name: 'Jorge O. Cáceres Dodds',
    position: 'Director',
    description:
      'Más de 30 años de experiencia en el sector de Warrants, desempeñándose desde el 2000 al 2019 como Gerente de Galicia Warrants NOA.',
    image: jorgeCaceres,
    email: 'jcaceresdodds@pampanyg.com.ar',
  },
]

const About = () => {
  return (
    <section className='overflow-hidden bg-white py-24'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <h3 className='mt-2 text-3xl font-bold sm:text-5xl text-center pb-10 tracking-wider'>
          NOSOTROS
        </h3>
        <p className='mt-6 text-lg leading-8 text-gray-600 text-center'>
          Pampa Negocios y Garantías S. A. es una sociedad constituida bajo legislación de la
          República Argentina y con las debidas inscripciones y autorizaciones del Ministerio de
          Agricultura, Ganadería y Pesca de la Nación para operar como depositaria de bienes muebles
          y emisora de Certificados de Depósito y Warrants de conformidad con la Ley de Warrants No
          9643.
        </p>
        <div
          className='hidden lg:block w-full bg-no-repeat bg-cover bg-fixed h-96 rounded-md my-5'
          style={{ backgroundImage: `url('${helmets}')` }}
        />
        <img
          className='lg:hidden w-full rounded-md my-5'
          src={helmetsMobile}
          alt='Nosotros | Pampa Negocios y Garantías S.A.'
        />
        <div className='mt-20'>
          <h3 className='mt-2 text-3xl font-bold sm:text-5xl text-center pb-10 tracking-wider'>
            NUESTRO EQUIPO
          </h3>
          <div className='grid grid-cols-2 gap-4 items-center justify-between'>
            {associates.map((associate) => (
              <div
                key={associate.name}
                className='col-span-2 lg:col-span-1 rounded-2xl bg-gray-100 p-5 w-full h-96 flex flex-col items-center justify-center gap-2'
              >
                <img
                  src={associate.image}
                  alt={associate.name}
                  className='w-40 h-40 rounded-full mx-auto'
                />
                <h4 className='text-2xl font-bold text-center text-gray-800'>{associate.name}</h4>
                <p className='text-lg text-center text-gray-600'>{associate.position}</p>
                <p className='text-md text-center text-gray-600'>{associate.description}</p>
                <a
                  className='text-center font-semibold text-pampa-blue text-lg'
                  href='mailto:ccaceres@pampanyg.com.ar'
                >
                  {associate.email}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className='mt-20 shadow rounded-md py-10'>
          <h3 className='mt-2 text-3xl font-bold sm:text-5xl text-center pb-10 tracking-wider'>
            NUESTRA VISIÓN
          </h3>
          <p className='mt-6 text-lg leading-8 text-gray-600 text-center'>
            Impulsamos el crecimiento de las empresas mediante la emisión segura y eficiente de
            certificados de warrants electrónicos, ofreciendo análisis exhaustivos y servicios de
            auditoría para atraer inversiones estratégicas y maximizar el potencial de quienes
            eligen trabajar con nosotros.
          </p>
        </div>
        <div className='w-full flex items-center justify-center mt-10'>
          <img src={logoPampa} alt='Pampa Negocios y Garantías S.A.' className='w-80' />
        </div>
        <Slider />
      </div>
    </section>
  )
}

export default About
