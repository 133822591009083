import warrants from '../../assets/garantias.jpg'

const Section = () => {
  return (
    <div className='overflow-hidden bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto grid items-center max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
          <div className='lg:ml-auto lg:pl-4 lg:pt-4'>
            <div className='lg:max-w-lg'>
              <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                Garantía y seguridad en la emisión de warrants electrónicos
              </p>
              <p className='mt-6 text-lg leading-8 text-gray-600'>
                En Pampa Negocios y Garantías nos dedicamos a la emisión de títulos y certificados
                de warrants electrónicos a través del Magyp. Para garantizar un proceso óptimo y
                seguro, realizamos un análisis exhaustivo del producto y sector, evaluamos la
                solvencia y referencias comerciales del depositante, llevamos a cabo inspecciones
                técnicas para asegurar la calidad y seguridad del almacenaje, revisamos pólizas, y
                realizamos inspecciones periódicas y auditorías coordinadas con depositantes,
                compañías de seguros y entidades financieras.
              </p>
            </div>
          </div>
          <div className='flex items-start justify-end lg:order-first'>
            <img
              src={warrants}
              alt=' Garantía y seguridad en la emisión de warrants electrónicos | Pampa Negocios y Garantías'
              className='w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10'
              height={1442}
              width={2432}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section
