import { Link } from 'react-router-dom'
import { NavLinksInterface } from '../../types'

const NavItems = ({ links, onClick }: { links: NavLinksInterface[]; onClick: () => void }) => {
  return (
    <>
      <div className='flex lg:hidden flex-col gap-4 lg:flex-row lg:items-center'>
        {links.map((link: NavLinksInterface) => {
          if (link.name === 'Servicios') {
            return (
              <a
                href={link.path}
                key={link.id}
                className='uppercase hover:text-pampa-lightBlue font-bold tracking-wider'
              >
                {link.name}
              </a>
            )
          } else {
            return (
              <Link
                to={link.path}
                key={link.id}
                className='uppercase hover:text-pampa-lightBlue font-bold tracking-wider'
                onClick={onClick}
              >
                {link.name}
              </Link>
            )
          }
        })}
      </div>
      <div className='hidden lg:flex gap-4 items-center'>
        {links.map((link: NavLinksInterface) => {
          if (link.name === 'Servicios') {
            return (
              <a
                href={link.path}
                key={link.id}
                className='uppercase hover:text-pampa-lightBlue font-bold tracking-wider'
              >
                {link.name}
              </a>
            )
          } else {
            return (
              <Link
                to={link.path}
                key={link.id}
                className='uppercase hover:text-pampa-lightBlue font-bold tracking-wider'
              >
                {link.name}
              </Link>
            )
          }
        })}
      </div>
    </>
  )
}

export default NavItems
