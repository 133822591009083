import trucks from '../../assets/trucks.jpg'
import monitoring from '../../assets/monitoring.svg'
import inventory from '../../assets/inventory.svg'
import manageSearch from '../../assets/manage_search.svg'
import twoPager from '../../assets/two_pager.svg'
import verified from '../../assets/verified.svg'
import engineering from '../../assets/engineering.svg'
import personSearch from '../../assets/person_search.svg'

interface ItemsInterface {
  id: number
  title: string
  icon: string
}

const items: ItemsInterface[] = [
  {
    id: 1,
    title: 'Análisis de producto / sectorial.',
    icon: monitoring,
  },
  {
    id: 2,
    title:
      'Análisis del depositante. Referencias comerciales. Análisis de poderes. Historial crediticio.',
    icon: inventory,
  },
  {
    id: 3,
    title:
      'Análisis técnico a través de nuestros inspectores, quienes califican las condiciones de almacenaje en calidad, cantidad y seguridad.',
    icon: manageSearch,
  },
  {
    id: 4,
    title: 'Seguros.',
    icon: twoPager,
  },
  {
    id: 5,
    title: `Emisión de títulos. Una vez conformada por las partes intervinientes se emiten los certificados de warrants electrónicos a través del <a style='color: blue; text-decoration: underline;' href='https://www.magyp.gob.ar/sitio/areas/ss_mercados_agropecuarios/_warrants/_archivos/000997_Empresas%20Warrants/000011_Habilitadas.php' target='_blank' rel='noreferrer'>Magyp</a>.`,
    icon: verified,
  },
  {
    id: 6,
    title:
      'Inspecciones periódicas definidas por la Cía. de seguro y conforme al riesgo asumido del producto; verificando stock, calidad de almacenaje, cartelería y precintos.',
    icon: engineering,
  },
  {
    id: 7,
    title:
      'Auditorías coordinadas con el depositante/ Cía. de seguro y financista a fin de verificar el correcto funcionamiento.',
    icon: personSearch,
  },
]

const Services = () => {
  return (
    <div
      className='overflow-hidden bg-white py-24 sm:py-32 bg-cover bg-no-repeat bg-center relative bg-fixed rounded-md'
      style={{ backgroundImage: `url('${trucks}')` }}
      id='services'
    >
      <div className='absolute w-full h-full bg-black/30 z-10 top-0 left-0 blur-sm' />
      <div className='mx-auto max-w-7xl px-6 lg:px-8 relative z-20'>
        <h3 className='mt-2 text-3xl font-bold text-white sm:text-5xl text-center pb-10 tracking-wider drop-shadow-pampa'>
          NUESTROS SERVICIOS
        </h3>
        <div className='grid grid-cols-3 gap-4 w-full'>
          {items.map((item) => (
            <div
              key={item.id}
              className={`${
                item.id === 7
                  ? 'col-span-3 lg:col-span-3 justify-self-center'
                  : 'col-span-3 lg:col-span-1'
              } bg-white rounded-lg border w-full flex flex-col items-center justify-center gap-3 p-4 hover:shadow-lg transition duration-300 ease-in-out`}
            >
              <img className='text-pampa-blue w-20' src={item.icon} alt={item.title} />
              <p className='text-sm text-center' dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Services
