import { Hero, Section, Slider, Services } from '../../components'

const Home = () => {
  return (
    <div>
      <Hero />
      <Section />
      <div className='h-[5px] bg-gray-200 w-full rounded' />
      <Services />
      <Slider title />
    </div>
  )
}

export default Home
