import { useState } from 'react'
import { ContactRequestInterface } from '../types'
import axios from '../axios-orders'

const useContact = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newContact, setNewContact] = useState<ContactRequestInterface>()

  const onCreateContact = (body: ContactRequestInterface) => {
    setIsLoading(true)
    axios
      .post('/contacts', body)
      .then((res) => setNewContact(res))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  return {
    isLoading,
    newContact,
    onCreateContact,
  }
}

export default useContact
