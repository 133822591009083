import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

const warrantTypes = [
  { id: 1, title: 'Warrant Comercial' },
  { id: 2, title: 'Warrant Crediticio' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Warrants = () => {
  const [selectedWarrant, setSelectedWarrant] = useState(warrantTypes[0])

  return (
    <section className='overflow-hidden bg-white py-24'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <h3 className='mt-2 text-xl font-bold sm:text-5xl text-center tracking-wider pb-10'>
          WARRANTS
        </h3>
        <fieldset>
          <RadioGroup
            value={selectedWarrant}
            onChange={setSelectedWarrant}
            className='mt-6 grid grid-cols-2 gap-y-6 gap-x-4'
          >
            {warrantTypes.map((warrant) => (
              <Radio
                key={warrant.id}
                value={warrant}
                aria-label={warrant.title}
                className={({ focus }) =>
                  classNames(
                    focus ? 'border-pampa-blue ring-2 ring-pampa-blue' : '',
                    !focus ? 'border-gray-300' : '',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
                  )
                }
              >
                {({ checked, focus }) => (
                  <>
                    <span className='flex flex-1'>
                      <span className='flex flex-col'>
                        <span className='block text-lg font-medium text-gray-900'>
                          {warrant.title}
                        </span>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-pampa-blue')}
                      aria-hidden='true'
                    />
                    <span
                      className={classNames(
                        checked ? 'border-pampa-blue' : 'border-transparent',
                        focus ? 'border' : 'border-2',
                        'pointer-events-none absolute -inset-px rounded-lg',
                      )}
                      aria-hidden='true'
                    />
                  </>
                )}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>
        {selectedWarrant.id === 1 && (
          <div className='pt-2'>
            <p className='mt-6 text-base leading-7 text-gray-900'>
              Un warrant comercial es un certificado que garantiza la propiedad de bienes físicos
              almacenados, como productos agrícolas o materias primas. Es utilizado principalmente
              en el comercio y la logística. Los puntos clave son:
            </p>
            <div className='mx-auto mt-16 grid grid-cols-4 gap-2'>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-100 p-8'>
                <p className='flex-none text-xl font-bold tracking-tight text-gray-900'>
                  PROPÓSITO
                </p>
                <p className='mt-2 text-base leading-7 text-black'>
                  Facilita el comercio de bienes físicos al servir como prueba de propiedad y
                  garantizar el control sobre las mercancías almacenadas.
                </p>
              </div>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 lg:gap-y-44'>
                <p className='flex-none text-xl font-bold tracking-tight text-white'>USO</p>
                <p className='mt-2 text-base leading-7 text-white'>
                  Los comerciantes y productores utilizan warrants comerciales para asegurar el
                  control de la cantidad y calidad de la mercadería de los bienes almacenados como
                  garantía.
                </p>
              </div>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-pampa-blue p-8'>
                <p className='text-xl font-bold tracking-tight text-white'>ALMACENAMIENTO</p>
                <p className='mt-2 text-base leading-7 text-white'>
                  Los bienes son almacenados en depósitos autorizados, y el warrant asegura que
                  estos bienes están disponibles y en buenas condiciones.
                </p>
              </div>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-pampa-lightBlue p-8'>
                <p className='flex-none text-xl font-bold tracking-tight text-white'>VALOR</p>
                <p className='mt-2 text-base leading-7 text-white'>
                  El valor del warrant está directamente ligado al valor de los bienes físicos que
                  representa.
                </p>
              </div>
            </div>
          </div>
        )}
        {selectedWarrant.id === 2 && (
          <div className='pt-2'>
            <p className='mt-6 text-base leading-7 text-gray-900'>
              Un warrant crediticio es una herramienta que permite a las empresas obtener mejores
              condiciones de financiación utilizando sus mercancías y/o producciones como garantía
              sin necesidad de venderlos. Los puntos clave son:
            </p>
            <div className='mx-auto mt-16 grid grid-cols-4 gap-2'>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-100 p-8'>
                <p className='flex-none text-xl font-bold tracking-tight text-gray-900'>
                  PROPÓSITO
                </p>
                <p className='mt-2 text-base leading-7 text-black'>
                  Permite a los inversores especular sobre el movimiento de los precios de los
                  activos subyacentes o cubrir riesgos de inversión.
                </p>
              </div>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 lg:gap-y-44'>
                <p className='flex-none text-xl font-bold tracking-tight text-white'>USO</p>
                <p className='mt-2 text-base leading-7 text-white'>
                  Los inversores utilizan warrants financieros para obtener ganancias potenciales de
                  los cambios en el precio de los activos subyacentes sin necesidad de poseerlos
                  físicamente.
                </p>
              </div>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-pampa-blue p-8'>
                <p className='text-xl font-bold tracking-tight text-white'>ACTIVOS SUBYACENTES</p>
                <p className='mt-2 text-base leading-7 text-white'>
                  El activo subyacente puede ser una acción, un bono, un índice o cualquier otro
                  activo financiero.
                </p>
              </div>
              <div className='col-span-4 sm:col-span-2 lg:col-span-1 flex flex-col justify-between gap-x-16 gap-y-8 rounded-2xl bg-pampa-lightBlue p-8'>
                <p className='flex-none text-xl font-bold tracking-tight text-white'>VALOR</p>
                <p className='mt-2 text-base leading-7 text-white'>
                  El valor del warrant financiero está ligado al precio del activo subyacente y
                  otros factores como la volatilidad y el tiempo hasta el vencimiento.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Warrants
