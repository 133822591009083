import { Route, Routes } from 'react-router'
import { Layout, ScrollToTop } from './components'
import { About, Contact, Home, Warrants } from './pages'

function App() {
  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/warrants' element={<Warrants />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </Layout>
  )
}

export default App
