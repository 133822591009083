import agrocereales from '../../assets/agrocereales.png'
import arcor from '../../assets/arcor.png'
import bagley from '../../assets/bagley.png'
import bancociudad from '../../assets/bancociudad.png'
import bancomf from '../../assets/bancomf.png'
import bbva from '../../assets/bbva.png'
import budeguer from '../../assets/budeguer.png'
import coopagroTandil from '../../assets/coopagro-tandil.png'
import cota from '../../assets/cota.png'
import espatina from '../../assets/espatina.png'
import galicia from '../../assets/galicia.png'
import icbc from '../../assets/icbc.png'
import imagessdas from '../../assets/imagessdas.png'
import ingenioLeales from '../../assets/ingenio-leales.jpg'
import internet from '../../assets/internet.png'
import lagomarsino from '../../assets/lagomarsino.png'
import lemon from '../../assets/lemon.png'
import macro from '../../assets/macro.jpg'
import navas from '../../assets/navas.png'
import nrg from '../../assets/nrg.png'
import quilmes from '../../assets/quilmes.png'
import rusticana from '../../assets/rusticana.png'
import sancor from '../../assets/sancor.png'
import supervielle from '../../assets/supervielle.png'
import veronica from '../../assets/veronica.png'
import zofingen from '../../assets/zofingen.png'

const icons = [
  { id: 1, src: agrocereales, alt: 'Agrocereales' },
  { id: 2, src: arcor, alt: 'Arcor' },
  { id: 3, src: bagley, alt: 'Bagley' },
  { id: 4, src: bancociudad, alt: 'Banco Ciudad' },
  { id: 5, src: bancomf, alt: 'Banco MF' },
  { id: 6, src: bbva, alt: 'BBVA' },
  { id: 7, src: budeguer, alt: 'Budeguer' },
  { id: 8, src: coopagroTandil, alt: 'Coop Agro Tandil' },
  { id: 9, src: cota, alt: 'Cota' },
  { id: 10, src: espatina, alt: 'Espatina' },
  { id: 11, src: galicia, alt: 'Galicia' },
  { id: 12, src: icbc, alt: 'ICBC' },
  { id: 13, src: imagessdas, alt: 'Imagessdas' },
  { id: 14, src: internet, alt: 'Internet' },
  { id: 15, src: ingenioLeales, alt: 'Ingenio Leales' },
  { id: 16, src: lagomarsino, alt: 'Lagomarsino' },
  { id: 17, src: lemon, alt: 'Lemon' },
  { id: 18, src: macro, alt: 'Macro' },
  { id: 19, src: navas, alt: 'Navas' },
  { id: 20, src: nrg, alt: 'NRG' },
  { id: 21, src: quilmes, alt: 'Quilmes' },
  { id: 22, src: rusticana, alt: 'Rusticana' },
  { id: 23, src: sancor, alt: 'Sancor' },
  { id: 24, src: supervielle, alt: 'Supervielle' },
  { id: 25, src: veronica, alt: 'Veronica' },
  { id: 26, src: zofingen, alt: 'Zofingen' },
]

const Slider = ({ title }: { title?: boolean }) => {
  return (
    <div className='overflow-hidden bg-white py-24'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        {title && (
          <h3 className='mt-2 text-3xl font-bold text-gray-900 sm:text-5xl text-center pb-10 tracking-wider'>
            NUESTROS CLIENTES
          </h3>
        )}
        <div
          x-data='{}'
          x-init="$nextTick(() => {
                        let ul = $refs.logos;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
          className='w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)] pb-10'
        >
          <ul
            x-ref='logos'
            className='flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll'
          >
            {icons.map((icon) => (
              <li key={icon.id}>
                <img className='w-[110px]' src={icon.src} alt={icon.alt} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Slider
