import linkedin from '../../assets/linkedin.webp'

const navigation = [
  {
    name: 'Linkedin',
    href: 'https://www.linkedin.com/company/pampa-negocios-y-garant%C3%ADas/about/',
    icon: linkedin,
  },
]

const Footer = () => {
  return (
    <div className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none'>
          <div>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900'>Contacto</h2>
          </div>
          <div className='col-span-2 rounded-md bg-gray-100 p-5 w-full'>
            <dl className='space-y-1 text-md leading-6 text-gray-600'>
              <div>
                <dt className='sr-only'>Email</dt>
                <dd>
                  <a
                    className='font-semibold text-pampa-blue text-lg'
                    href='mailto:spasman@pampanyg.com.ar'
                  >
                    spasman@pampanyg.com.ar
                  </a>
                </dd>
              </div>
              <div>
                <dt className='sr-only'>Email</dt>
                <dd>
                  <a
                    className='font-semibold text-pampa-blue text-lg'
                    href='mailto:jcaceresdodds@pampanyg.com.ar'
                  >
                    jcaceresdodds@pampanyg.com.ar
                  </a>
                </dd>
              </div>
              <div>
                <dt className='sr-only'>Email</dt>
                <dd>
                  <a
                    className='font-semibold text-pampa-blue text-lg'
                    href='mailto:ccaceres@pampanyg.com.ar'
                  >
                    ccaceres@pampanyg.com.ar
                  </a>
                </dd>
              </div>
              <div className='mt-1 text-lg'>
                <dt className='sr-only'>Teléfono</dt>
                <dd>+54 9 3815 08 6064</dd>
              </div>
            </dl>
          </div>
          <footer className='bg-white'>
            <div className='mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8'>
              <div className='flex justify-center space-x-6 md:order-2 items-center'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className='text-gray-400 hover:text-gray-500'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='sr-only'>{item.name}</span>
                    <img src={item.icon} className='h-6 w-6' aria-hidden='true' alt={item.name} />
                  </a>
                ))}
              </div>
              <div className='mt-8 md:order-1 md:mt-0'>
                <p className='text-center text-xs leading-5 text-gray-500'>
                  &copy; {new Date().getFullYear()} Pampa Negocios y Garantías S.A. Todos los
                  derechos reservados.
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Footer
