import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useContact } from '../../hooks'
import { BounceLoader } from 'react-spinners'

const validationSchema = Yup.object({
  name: Yup.string().required('Ingrese su nombre'),
  company: Yup.string().required('Ingrese su empresa'),
  email: Yup.string().email('Ingrese un email válido').required('Ingrese su email'),
  message: Yup.string().required('Ingrese un mensaje'),
})

interface FormValues {
  name: string
  company: string
  email: string
  phone?: string
  message: string
}

const Contact = () => {
  const { newContact, isLoading, onCreateContact } = useContact()
  return (
    <div className='isolate bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div
        className='absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]'
        aria-hidden='true'
      >
        <div
          className='relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pampa-lightBlue to-pampa-blue opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]'
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className='mx-auto max-w-xl text-center'>
        <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>Contacto</h2>
        {newContact && (newContact.status === 201 || newContact.status === 200) && (
          <p className='text-white p-2 bg-green-700 font-bold rounded-md text-center w-full mt-4'>
            El mensaje se envió correctamente!
          </p>
        )}
      </div>

      {isLoading ? (
        <div className='w-full flex items-center justify-center pt-20'>
          <BounceLoader color='#083b8d' loading />
        </div>
      ) : (
        <Formik
          initialValues={{
            name: '',
            company: '',
            email: '',
            phone: '',
            message: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values: FormValues) => {
            onCreateContact({ data: { ...values } })
          }}
        >
          {({ isValid }) => {
            return (
              <Form className='mx-auto mt-16 max-w-xl sm:mt-10'>
                <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                  <div className='col-span-2 md:col-span-1'>
                    <label htmlFor='name'>
                      Nombre Completo <span className='text-red-500'>*</span>
                    </label>
                    <Field
                      id='name'
                      name='name'
                      type='text'
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                    <ErrorMessage
                      name='name'
                      render={(msg) => <div className='text-red-500 text-xs'>{msg}</div>}
                    />
                  </div>
                  <div className='col-span-2 md:col-span-1'>
                    <label htmlFor='company'>
                      Empresa <span className='text-red-500'>*</span>
                    </label>
                    <Field
                      id='company'
                      name='company'
                      type='text'
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                    <ErrorMessage
                      name='company'
                      render={(msg) => <div className='text-red-500 text-xs'>{msg}</div>}
                    />
                  </div>
                  <div className='col-span-2'>
                    <label htmlFor='email'>
                      Email <span className='text-red-500'>*</span>
                    </label>
                    <Field
                      id='email'
                      name='email'
                      type='email'
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                    <ErrorMessage
                      name='email'
                      render={(msg) => <div className='text-red-500 text-xs'>{msg}</div>}
                    />
                  </div>
                  <div className='col-span-2'>
                    <label htmlFor='phone'>Teléfono</label>
                    <Field
                      id='phone'
                      name='phone'
                      type='phone'
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                  <div className='col-span-2'>
                    <label htmlFor='message'>
                      Mensaje <span className='text-red-500'>*</span>
                    </label>
                    <Field
                      id='message'
                      name='message'
                      as='textarea'
                      col={4}
                      rows={10}
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                    <ErrorMessage
                      name='message'
                      render={(msg) => <div className='text-red-500 text-xs'>{msg}</div>}
                    />
                  </div>
                </div>
                <div className='mt-10'>
                  <button
                    type='submit'
                    className='block w-full rounded-md bg-pampa-blue px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-pampa-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pampa-blue disabled:bg-gray-300 disabled:cursor-not-allowed uppercase'
                    disabled={!isValid}
                  >
                    Enviar
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default Contact
